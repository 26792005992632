import PropTypes from 'prop-types';

const newsletterFormPropTypes = {
  pretitle: PropTypes.shape({
    enabled: PropTypes.bool,
    text: PropTypes.string,
  }).isRequired,
  title: PropTypes.shape({
    enabled: PropTypes.bool,
    text: PropTypes.string,
  }).isRequired,
  description: PropTypes.shape({
    enabled: PropTypes.bool,
    text: PropTypes.string,
  }).isRequired,
  inputs: PropTypes.shape({
    email: PropTypes.shape({
      label: PropTypes.string,
      placeholder: PropTypes.string,
    }).isRequired,
  }).isRequired,
  buttons: PropTypes.shape({
    primary: PropTypes.shape({
      text: PropTypes.string,
    }).isRequired,
  }).isRequired,
  disclaimer: PropTypes.shape({
    text: PropTypes.string,
    link_text: PropTypes.string,
    link_url: PropTypes.string,
  }).isRequired,
};

export default newsletterFormPropTypes;
