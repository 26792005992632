/* eslint-disable no-undef */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import NewsletterModal from '@mshops-web-components/newsletter-modal';

import wordings from './wordings';
import newsletterFormPropTypes from './utils/formProps';

import ClientService from '../../../services/client';

import { trackEvent } from '../../utils/tracking';
import tracks from '../../utils/tracks';

import {
  NEWSLETTER_SUBSCRIPTION_COOKIE,
  NEWSLETTER_SUBSCRIPTION_EXPIRATION,
  RECAPTCHA,
} from '../../utils/constants/newsletterModal';
import { useRenderContext } from '../../pages/home/context';

const NewsletterModalWrapper = (props) => {
  const { i18n, form, site_key: siteKey } = props;
  const { SUBSCRIBE_MODAL_ACTION } = RECAPTCHA;
  const locales = wordings(i18n);
  const { is_open: isOpen } = form;
  if (!isOpen) {
    return null;
  }

  const {
    device,
    hideModalSubscription,
    isHubPreview,
    restClient,
    shopModel,
  } = useRenderContext();

  const { domain, id, name } = shopModel.shop;
  const commonTrack = {
    shop_id: id,
    shop_name: name,
    shop_domain: domain,
  };

  const [showCongrats, setShowCongrats] = useState(false);
  const [showModal, setShowModal] = useState(!hideModalSubscription);
  const [isFetching, setIsFetching] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const closeModal = () => {
    if (!showCongrats) {
      // Convert to seconds because when cookie is set on server side, express expects max-age to be in milliseconds
      const expirationInSeconds = NEWSLETTER_SUBSCRIPTION_EXPIRATION / 1000;
      document.cookie = `${NEWSLETTER_SUBSCRIPTION_COOKIE}=true; max-age=${expirationInSeconds}; path=/`;
    }

    setShowModal(false);
  };

  const subscribeEmail = async (email, token) => {
    const request = new ClientService(restClient.baseURL);
    setIsFetching(true);
    trackEvent(tracks.newsletterSubscription, commonTrack);
    try {
      await request.post('/automations/subscribe-newsletter', {
        data: {
          email,
          token,
          fromModal: true,
        },
      });
      trackEvent(tracks.newsletterCongratsView, commonTrack);
      setShowCongrats(true);
    } catch (err) {
      const errorLabel = locales[err?.response?.data?.messageCode] || locales.GeneralError;
      setErrorMessage(errorLabel);
    }
    setIsFetching(false);
  };

  const handleSubscription = (email) => {
    // TODO: Remove this when appearance deploy is completed
    if (typeof grecaptcha === 'undefined' || !siteKey) {
      subscribeEmail(email);
      return;
    }

    grecaptcha.enterprise.ready(async () => {
      try {
        const token = await grecaptcha.enterprise.execute(siteKey, {
          action: SUBSCRIBE_MODAL_ACTION,
        });

        subscribeEmail(email, token);
      } catch (error) {
        setErrorMessage(locales.GeneralError);
      }
    });
  };

  if (isHubPreview) {
    return null;
  }

  useEffect(() => {
    if (showModal) {
      trackEvent(tracks.newsletterView, commonTrack);
    }
  }, []);

  return (
    <NewsletterModal
      {...props}
      deviceType={device}
      isOpen={showModal}
      handleSubscription={handleSubscription}
      isCongrats={showCongrats}
      closeModal={closeModal}
      isFetching={isFetching}
      errorMessage={errorMessage}
    />
  );
};

NewsletterModalWrapper.propTypes = {
  site_key: PropTypes.string.isRequired,
  form: PropTypes.shape({
    ...newsletterFormPropTypes,
  }),
  images: PropTypes.shape({
    desktop: PropTypes.shape({
      id: PropTypes.string,
      url: PropTypes.string,
    }),
    mobile: PropTypes.shape({
      id: PropTypes.string,
      url: PropTypes.string,
    }),
  }).isRequired,
  campaign_id: PropTypes.number,
};

export default NewsletterModalWrapper;
